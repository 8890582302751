/* COLORS */
/* TYPOGRAPHY */
h1,
h2,
h3,
h4,
h5,
h6,
.button,
button,
label,
input,
textarea {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase; }

p {
  font-family: "Roboto", sans-serif;
  line-height: 1.4em; }

h1 {
  font-size: 2em; }

img {
  max-width: 100%; }

body {
  background-color: #fff;
  margin: 0;
  padding: 0; }

.pink-box {
  background: #f75353;
  color: #fff;
  width: 50%;
  padding: 5px 15px;
  border-left: 20px solid #222; }

/* HERO */
.logo {
  width: 525px; }

.poly-hero {
  background: url("/img/bg-poly.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: #010101;
  height: 100vh;
  display: table;
  width: 100%; }

.hero-content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 15px; }
  .hero-content h1 {
    margin: 0; }
  .hero-content h2 {
    color: #fff;
    font-size: 0.9em;
    letter-spacing: 0.65em;
    margin-bottom: 40px; }
  .hero-content h3 {
    color: #fff;
    font-size: 1.4em;
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: center; }
    .hero-content h3 span {
      color: rgba(255, 255, 255, 0.6); }
  .hero-content p {
    color: #fff;
    font-size: 1em;
    text-align: center;
    padding: 0;
    margin: 5px 0 20px; }

#cta {
  padding: 8px 30px;
  border: 1px solid #f75353;
  background: rgba(0, 0, 0, 0.4);
  min-height: 200px;
  max-width: 45rem;
  margin: 0 auto; }

.statement {
  clear: both;
  overflow: auto;
  margin: 0 auto;
  text-align: center; }

.button {
  padding: 10px 20px;
  background: #f75353;
  border: 2px solid #f75353;
  color: #fff;
  text-decoration: none;
  display: inline-block; }
  .button:hover {
    background: transparent;
    color: #f75353; }

.b-mask {
  margin-top: 80px;
  background: url("/img/bg-abstract.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat; }

/* MIXIN IT UP */
.contact {
  padding-bottom: 1em;
  height: 100vh;
  margin: 0 auto;
  background: url("/img/contact-background.png");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat; }
  .contact .contact-form {
    padding-top: 10vh; }
  .contact h1 {
    text-align: center; }
  .contact .form {
    padding: 0 15px; }
    .contact .form:after {
      content: "";
      display: table;
      clear: both; }
    .contact .form label {
      font-size: .9em;
      color: #f75353; }
  .contact input {
    height: 3em;
    margin-bottom: 1em;
    padding: 0 1em;
    width: 100%;
    box-sizing: border-box; }
  .contact textarea {
    padding: 1em;
    height: 11em;
    width: 100%;
    box-sizing: border-box; }
  .contact input, .contact textarea {
    border: 1px solid #000; }
    .contact input:focus, .contact textarea:focus {
      box-shadow: none;
      border: 1px solid #f75353;
      outline: 0 none;
      padding: 5px; }
    .contact input::-webkit-input-placeholder, .contact textarea::-webkit-input-placeholder {
      font-family: 'Oswald', sans-serif;
      text-transform: uppercase;
      color: #CCC; }
    .contact input:-moz-placeholder, .contact textarea:-moz-placeholder {
      font-family: 'Oswald', sans-serif;
      text-transform: uppercase;
      color: #CCC; }
    .contact input::-moz-placeholder, .contact textarea::-moz-placeholder {
      font-family: 'Oswald', sans-serif;
      text-transform: uppercase;
      color: #CCC; }
    .contact input:-ms-input-placeholder, .contact textarea:-ms-input-placeholder {
      font-family: 'Oswald', sans-serif;
      text-transform: uppercase;
      color: #CCC; }
  .contact .submit {
    margin-bottom: 20px; }
    .contact .submit button {
      text-align: center;
      margin: 0 auto;
      display: block;
      padding: 10px 20px;
      background: #f75353;
      border: 2px solid #f75353;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      font-size: 1em; }
      .contact .submit button:hover {
        background: transparent;
        color: #f75353; }

.s-alert-box-inner {
  text-align: center; }

.s-alert-warning {
  background: #f75353; }
